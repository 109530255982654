// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 20px 0;
    justify-content: center;
  }
  
  .page-item {
    margin: 0 5px;
  }
  
  .page-link {
    display: block;
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    text-decoration: none;
    color: #000000;
  }
  
  .page-link:hover {
    background-color: #f8f9fa;
    border-color: #000000;
  }
  
  .active .page-link {
    background-color: #000000;
    color: white;
    border-color: #000000;
  }
  
  .previous,
  .next {
    display: flex;
    align-items: center;
  }
  `, "",{"version":3,"sources":["webpack://./src/Admin/Components/admin.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,UAAU;IACV,cAAc;IACd,uBAAuB;EACzB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,sBAAsB;IACtB,kBAAkB;IAClB,qBAAqB;IACrB,cAAc;EAChB;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;EACvB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,qBAAqB;EACvB;;EAEA;;IAEE,aAAa;IACb,mBAAmB;EACrB","sourcesContent":[".pagination {\r\n    display: flex;\r\n    list-style: none;\r\n    padding: 0;\r\n    margin: 20px 0;\r\n    justify-content: center;\r\n  }\r\n  \r\n  .page-item {\r\n    margin: 0 5px;\r\n  }\r\n  \r\n  .page-link {\r\n    display: block;\r\n    padding: 10px 15px;\r\n    border: 1px solid #ddd;\r\n    border-radius: 4px;\r\n    text-decoration: none;\r\n    color: #000000;\r\n  }\r\n  \r\n  .page-link:hover {\r\n    background-color: #f8f9fa;\r\n    border-color: #000000;\r\n  }\r\n  \r\n  .active .page-link {\r\n    background-color: #000000;\r\n    color: white;\r\n    border-color: #000000;\r\n  }\r\n  \r\n  .previous,\r\n  .next {\r\n    display: flex;\r\n    align-items: center;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
